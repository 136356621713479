<!--
 * @Author: your name
 * @Date: 2020-03-23 11:19:40
 * @LastEditTime: 2020-09-27 15:41:15
 * @LastEditors: zhongtao
 * @Description: In User Settings Edit
 * @FilePath: \onesports-cloud-view-new\src\views\venueMana\venue\dialog\addDialog.vue
 -->
<template>
  <div class="dialog-container">
    <el-dialog
      :title="`${isEdit ? '修改设备' : '新增设备'}`"
      :visible.sync="show"
      @opened="openDialog"
      :before-close="closeDialog"
      center
      :close-on-click-modal="false"
      width="50%"
    >
    <el-form ref="form" :model="form" label-width="120px" :rules="rules">
        <el-row>
          <el-col :span="24">
            <el-form-item label="场馆/中心" prop="venueName" >
              <el-input v-model="form.venueName" maxlength="30" disabled></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="运动项目" prop="sportsProjectCode">
              <el-select v-model="form.sportsProjectCode" style="width:100%" clearable >
                <el-option v-for="item in sportsProjectList" :key="item.sportsProjectCode" :label="item.sportsProjectName" :value="item.sportsProjectCode"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="设备ID" prop="deviceCode">
              <el-input clearable v-model="form.deviceCode" placeholder="请输入" maxlength="30"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="设备名称" prop="deviceName">
              <el-input clearable v-model="form.deviceName" placeholder="请输入" maxlength="30"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="设备型号" prop="deviceUnit">
              <el-input clearable v-model="form.deviceUnit" placeholder="请输入" maxlength="30"></el-input>
            </el-form-item>
          </el-col>
           <el-col :span="24">
            <el-form-item label="安装地点" prop="location">
              <el-input clearable v-model="form.location" placeholder="请输入" maxlength="30"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="首页显示" prop="display">
              <el-select v-model="form.display" style="width:100%">
                <el-option key="Y" label="是" value="Y"></el-option>
                <el-option key="N" label="否" value="N"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <template slot="footer">
        <!-- 如果没特别需求，统一使用mixin里面的关闭方法 -->
        <div class="footer-confirm" @click="onSave">{{$t('sure')}}</div>
        <div class="footer-cancel" @click="closeDialog">{{$t('cancel')}}</div>
      </template>
    </el-dialog>
  </div>
</template>
<script>
import { mapState, mapMutations } from "vuex";
import mixin from "@/mixins/dialogMixin";
import twoStepDialogMixin from "@/mixins/twoStepDialogMixin";
import AddDialogClass from "./addDialogClass";
import apis from "@/apis";

export default {
  mixins: [mixin, twoStepDialogMixin],
  props: {
    show: {
      required: true,
      type: Boolean,
    },
    isEdit: {
      required: true,
      type: Boolean,
    },
    Language: {
      required: false,
      type: Number,
      default: 0,
    },
    sportsProjectList: {
      required: false,
      type: Array,
    },
    Name: {
      required: true,
      type: String,
    },
  },
  mounted() {
    console.log("DROPDOWNBOX.VENUE_SERVICE", this.DROPDOWNBOX)
  },
  watch: {
    Name(newV) {
      this.form.venueName = newV;
    },
  },
  data() {
    return {
      form: new AddDialogClass("form"), // 实例化一个表单的变量
      rules: new AddDialogClass("rule", this.Language), // 实例化一个表单的规则
    };
  },
  computed: {
    ...mapState(["DROPDOWNBOX", "TOKEN", "venueList", "venueId"]),
  },
  methods: {
    /**
     * @function 关闭弹窗
     * @description 先初始化，然后传值到组件外部进行关闭
     **/
    async closeDialog() {
      await this.init()
      // this.$refs.bMap.clearData();
      this.$emit('closeDialog', false)
    },
    ...mapMutations(['setVenueId', "setVenueList"]),
    /**
     * @function 初始化弹窗
     */
    init() {
      this.$refs.form.resetFields();
      this.form = new AddDialogClass("form");
      console.log("我初始化了");
    },
    /**
     * @function 点击确定按钮，先执行业务，完了调关闭方法，不要写两次关闭方法
     */
    onSave() {
      // 这里写业务需求
      this.$refs.form.validate((valid) => {
        if (valid) {
          if (this.isEdit) {
            const dataForm = {
              id: this.form.id,
              sportsProjectCode: this.form.sportsProjectCode,
              deviceCode: this.form.deviceCode,
              deviceName: this.form.deviceName,
              deviceUnit: this.form.deviceUnit,
              location: this.form.location,
              deviceType: '9',
              display: this.form.display,
            }
            console.log("dataForm===>编辑", dataForm)
            this.$http.post(apis.updateIotDevice, { ...dataForm })
            .then((res) => {
              if (res.data.code === 0) {
                this.closeDialog();
                this.$message.success("修改成功!");
              }
            })
          } else {
            const addData = {
              ...this.form,
              deviceType: "9",
            };
            this.$http.post(apis.addIotDevice, { ...addData })
            .then((res) => {
              if (res.data.code === 0) {
                this.closeDialog();
                this.$message.success("添加成功!");
              }
            })
          }
        }
      })
    },
    getDataQuery(data) {
      console.log("在这里获取回显的数据", data)
      this.form = Object.assign({}, data)
    },
    openDialog() {
      this.form.venueName = this.Name;
    },
  },
};
</script>
<style lang="less" scoped>
.footer-confirm,.footer-cancel{
  line-height: 32px !important;
}
.image-upload {
  width: 50px;
  height: 50px;
}
.upload-images-tip {
  color:rgba(168,168,168,1);
  font-size:12px;
  margin-top: -15px;
}
.bmap-wrap {
  width: 100%;
  height: 300px;
}
.image-wrap{
  width: 50px;
  height: 50px;
  margin-right: 10px;
  position: relative;
  float: left;
  .images-close {
    position: absolute;
    right: -7px;
    top: -7px;
    width: 14px;
    height: 14px;
    cursor: pointer;
  }
}
</style>
<style lang="less">
@import "~@/assets/styles/mixin.less";

.service-wrapper {
  .el-checkbox {
    min-width: 80px !important;
  }
}
</style>
