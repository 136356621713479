/*
 * @Author: your name
 * @Date: 2020-03-23 11:19:40
 * @LastEditTime: 2020-10-14 17:37:03
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \onesports-cloud-view-new\src\views\venueMana\venue\dialog\addDialogClass.js
 */
/* eslint-disable */
class searchFrom {
  constructor(type) {
    if (type === 'form') {
      this.deviceType = '9'
      this.venueName = ''
      this.venueId = ''
      this.sportsProjectCode= ''
      this.sportsProjectName = ''
      this.deviceCode = ''
      this.deviceName = ''
      this.deviceUnit=''
      this.location = ''
      this.display = 'N'
    } else if (type === 'rule') {
      this.venueId = [{ required: true, message: '请选择场馆/中心',  trigger: 'blur' }]
      this.sportsProjectCode = [{ required: true, message: '请选择运动项目', trigger: ['change', 'blur'] }]
      this.sportsProjectName = [{ required: true, message: '请选择运动项目',  trigger: ['change', 'blur'] }]
      this.deviceCode = [{ required: true, message: '请输入设备ID', trigger: 'blur' }]
      this.deviceName = [{ required: true, message: '请输入设备名称', trigger: 'blur' }]
      this.deviceUnit = [{ required: true, message: '请输入设备型号', trigger: 'blur' }]
      this.location = [{ required: true, message: '请输入安装地点', trigger: 'blur' }]
      this.display = [{ required: true, message: '请选择首页是否显示', trigger: 'blur' }]
    }
  }
}
export default searchFrom
